/*!
 Column visibility buttons for Buttons and DataTables.
 2016 SpryMedia Ltd - datatables.net/license
*/
(function(f){"function"===typeof define&&define.amd?define(["jquery","datatables.net","datatables.net-buttons"],function(c){return f(c,window,document)}):"object"===typeof exports?module.exports=function(c,e){c||(c=window);e&&e.fn.dataTable||(e=require("datatables.net")(c,e).$);e.fn.dataTable.Buttons||require("datatables.net-buttons")(c,e);return f(e,c,c.document)}:f(jQuery,window,document)})(function(f,c,e,h){c=f.fn.dataTable;f.extend(c.ext.buttons,{colvis:function(b,a){return{extend:"collection",
text:function(a){return a.i18n("buttons.colvis","Column visibility")},className:"buttons-colvis",buttons:[{extend:"columnsToggle",columns:a.columns,columnText:a.columnText}]}},columnsToggle:function(b,a){return b.columns(a.columns).indexes().map(function(b){return{extend:"columnToggle",columns:b,columnText:a.columnText}}).toArray()},columnToggle:function(b,a){return{extend:"columnVisibility",columns:a.columns,columnText:a.columnText}},columnsVisibility:function(b,a){return b.columns(a.columns).indexes().map(function(b){return{extend:"columnVisibility",
columns:b,visibility:a.visibility,columnText:a.columnText}}).toArray()},columnVisibility:{columns:h,text:function(b,a,d){return d._columnText(b,d)},className:"buttons-columnVisibility",action:function(b,a,d,g){b=a.columns(g.columns);a=b.visible();b.visible(g.visibility!==h?g.visibility:!(a.length&&a[0]))},init:function(b,a,d){var g=this;a.attr("data-cv-idx",d.columns);b.on("column-visibility.dt"+d.namespace,function(a,c){c.bDestroying||c.nTable!=b.settings()[0].nTable||g.active(b.column(d.columns).visible())}).on("column-reorder.dt"+
d.namespace,function(c,g,e){1===b.columns(d.columns).count()&&(d.columns=f.inArray(d.columns,e.mapping),a.attr("data-cv-idx",d.columns),a.parent().children("[data-cv-idx]").sort(function(a,b){return 1*a.getAttribute("data-cv-idx")-1*b.getAttribute("data-cv-idx")}).appendTo(a.parent()))});this.active(b.column(d.columns).visible())},destroy:function(b,a,d){b.off("column-visibility.dt"+d.namespace).off("column-reorder.dt"+d.namespace)},_columnText:function(b,a){var d=b.column(a.columns).index(),c=b.settings()[0].aoColumns[d].sTitle.replace(/\n/g,
" ").replace(/<br\s*\/?>/gi," ").replace(/<select(.*?)<\/select>/g,"").replace(/<!\-\-.*?\-\->/g,"").replace(/<.*?>/g,"").replace(/^\s+|\s+$/g,"");return a.columnText?a.columnText(b,d,c):c}},colvisRestore:{className:"buttons-colvisRestore",text:function(b){return b.i18n("buttons.colvisRestore","Restore visibility")},init:function(b,a,d){d._visOriginal=b.columns().indexes().map(function(a){return b.column(a).visible()}).toArray()},action:function(b,a,d,c){a.columns().every(function(b){b=a.colReorder&&
a.colReorder.transpose?a.colReorder.transpose(b,"toOriginal"):b;this.visible(c._visOriginal[b])})}},colvisGroup:{className:"buttons-colvisGroup",action:function(b,a,d,c){a.columns(c.show).visible(!0,!1);a.columns(c.hide).visible(!1,!1);a.columns.adjust()},show:[],hide:[]}});return c.Buttons});
